import dayjs from 'dayjs';
import { AppConfig } from '../../models/config/appConfig';
import {
    DUE_ON_BEFORE,
    INVOICE_STATE,
    INVOICE_STATUS,
    INVOICE_TYPE,
    INVOICE_TYPE_DISPLAY_NAME,
    PAID_ON_SINCE,
    PageKey
} from '../../models/invoice';
import { InvoiceStatusColor, InvoiceTypeColor, PieChartColor } from '../constants/color';

export const getTypeColor = (type: string | undefined) => {
    type = type?.toLowerCase();
    switch (type) {
        case 'epdf':
            return InvoiceTypeColor.ePDF;
        case 'ebilling':
            return InvoiceTypeColor.eBilling;
        case 'physical':
            return InvoiceTypeColor.Physical;
        default:
            return InvoiceStatusColor.Other;
    }
};

export const getStatusColor = (status: string | undefined) => {
    return status && InvoiceStatusColor[status]
        ? InvoiceStatusColor[status]
        : InvoiceStatusColor.Other;
};

export const getInvoiceStatusColorForPie = (status: INVOICE_STATUS) => {
    const color = PieChartColor[status];
    return color ? color : InvoiceStatusColor.Other;
};

export const getInvoicePageKey: (
    invoiceState: INVOICE_STATE,
    invoiceType: INVOICE_TYPE
) => PageKey = (invoiceState: INVOICE_STATE, invoiceType: INVOICE_TYPE) => {
    switch (invoiceState) {
        case INVOICE_STATE.PENDING: {
            if (invoiceType === INVOICE_TYPE.EPDF) {
                return 'pendingEPdf';
            } else if (invoiceType === INVOICE_TYPE.PHYSICAL) {
                return 'pendingPhysical';
            } else {
                return 'pendingEBilling';
            }
        }
        case INVOICE_STATE.OUTSTANDING: {
            if (invoiceType === INVOICE_TYPE.EPDF) {
                return 'outstandingEPdf';
            } else if (invoiceType === INVOICE_TYPE.PHYSICAL) {
                return 'outstandingPhysical';
            } else {
                return 'outstandingEBilling';
            }
        }
        case INVOICE_STATE.PAID:
            return 'paid';
        case INVOICE_STATE.NEEDS_ATTENTION:
            return 'needsAttention';
        case INVOICE_STATE.CONSOLIDATED:
            return 'consolidated';
        default:
            return 'unknown';
    }
};

// Will be improved further based on the use cases
export class InvoiceURLSearchParamBuilder {
    urlSearchParams: URLSearchParams;
    invoiceState?: INVOICE_STATE;
    invoiceType?: INVOICE_TYPE;
    constructor(
        invoiceState?: INVOICE_STATE,
        invoiceType?: INVOICE_TYPE,
        params?: URLSearchParams
    ) {
        this.urlSearchParams = params ?? new URLSearchParams();
        this.invoiceType = invoiceType;
        this.invoiceState = invoiceState;
    }

    get isInvoiceStatePendingOrOutstanding() {
        return (
            this.invoiceState === INVOICE_STATE.PENDING ||
            this.invoiceState === INVOICE_STATE.OUTSTANDING
        );
    }

    get isInvoiceStateOutstandingOrPaid() {
        return (
            this.invoiceState === INVOICE_STATE.OUTSTANDING ||
            this.invoiceState === INVOICE_STATE.PAID
        );
    }

    get isInvoiceStateConsolidated() {
        return this.invoiceState === INVOICE_STATE.CONSOLIDATED;
    }

    get isInvoiceStateOutstanding() {
        return this.invoiceState === INVOICE_STATE.OUTSTANDING;
    }

    get isInvoiceStateNeedsAttention() {
        return this.invoiceState === INVOICE_STATE.NEEDS_ATTENTION;
    }

    get isInvoiceStatePaid() {
        return this.invoiceState === INVOICE_STATE.PAID;
    }

    get isInvoiceStatePending() {
        return this.invoiceState === INVOICE_STATE.PENDING;
    }

    addMyInvoices(myInvoices: boolean) {
        this.urlSearchParams.append('myInvoices', myInvoices ? 'true' : 'false');
        return this;
    }

    addInvoiceTypes(invoiceTypes: INVOICE_TYPE[]) {
        if (this.isInvoiceStatePendingOrOutstanding) {
            invoiceTypes.forEach((invoiceType) =>
                this.urlSearchParams.append('invoiceTypes', invoiceType)
            );
        }
        return this;
    }

    addInvoiceType(invoiceType: INVOICE_TYPE) {
        if (this.isInvoiceStatePendingOrOutstanding) {
            this.urlSearchParams.append('invoiceType', invoiceType);
        }
        return this;
    }

    addPaidInFull() {
        if (this.isInvoiceStatePaid) {
            this.urlSearchParams.append('paidInFull', 'true');
        } else {
            this.urlSearchParams.append('paidInFull', 'false');
        }
        return this;
    }

    addStatuses(invoiceStatuses: string[]) {
        if (this.isInvoiceStatePendingOrOutstanding) {
            invoiceStatuses.forEach((invoiceStatus) => {
                this.urlSearchParams.append('statuses', invoiceStatus);
            });
        }
        return this;
    }

    addSubmitted() {
        if (this.isInvoiceStateOutstandingOrPaid) {
            this.urlSearchParams.append('submitted', 'true');
        } else if (this.isInvoiceStatePending) {
            this.urlSearchParams.append('submitted', 'false');
        }
        return this;
    }

    addErrorsOnly(value?: string) {
        if (value) {
            this.urlSearchParams.append('errorsOnly', value);
            return this;
        }
        if (this.isInvoiceStateNeedsAttention) {
            this.urlSearchParams.append('errorsOnly', 'true');
        }
        return this;
    }

    addReversed(value?: boolean) {
        this.urlSearchParams.append('reversed', value ? 'true' : 'false');
        return this;
    }

    addBillingTimekeeperIds(billingTimekeeperIds: string[]) {
        if (this.isInvoiceStatePendingOrOutstanding) {
            billingTimekeeperIds.forEach((billingTimekeeperId) => {
                this.urlSearchParams.append('billingTimekeeperIds', billingTimekeeperId);
            });
        }
        return this;
    }

    addBillerIds(billerIds: string[]) {
        if (this.isInvoiceStatePendingOrOutstanding) {
            billerIds.forEach((billerId) => {
                this.urlSearchParams.append('billerIds', billerId);
            });
        }
        return this;
    }

    addClientIds(clientIds: string[]) {
        if (this.isInvoiceStatePendingOrOutstanding || this.isInvoiceStateConsolidated) {
            clientIds.forEach((clientId) => {
                this.urlSearchParams.append('clientIds', clientId);
            });
        }
        return this;
    }

    addSubGroupStatuses(statuses: string[]) {
        if (this.isInvoiceStateConsolidated) {
            statuses.forEach((status) => {
                this.urlSearchParams.append('subGroupStatuses', status);
            });
        }
        return this;
    }

    addPaidOnSince(paidOnSince: PAID_ON_SINCE) {
        if (this.isInvoiceStatePaid) {
            this.urlSearchParams.append('paidOnSince', getPaidOnSinceDate(paidOnSince));
        }
        return this;
    }

    addDueOnBefore(dueOnBefore: DUE_ON_BEFORE) {
        if (this.isInvoiceStateOutstanding) {
            if (dueOnBefore === DUE_ON_BEFORE.OVERDUE) {
                this.urlSearchParams.append('overdue', 'true');
            } else {
                this.urlSearchParams.append('dueOnBefore', getDueOnBeforeDate(dueOnBefore));
                this.urlSearchParams.append('overdue', 'false');
            }
        }
        return this;
    }

    addOverdue(overdue: boolean) {
        if (this.isInvoiceStateConsolidated && overdue) {
            this.urlSearchParams.append('overdue', 'true');
        }
        return this;
    }

    addWriteOff(writeOff: boolean) {
        if (this.isInvoiceStateConsolidated && writeOff) {
            this.urlSearchParams.append('writeOffPending', 'true');
        }
        return this;
    }

    addNeedsSubmission(value: boolean) {
        if (this.isInvoiceStateConsolidated && value) {
            this.urlSearchParams.append('needsSubmissionByTimekeeper', 'true');
        }
        return this;
    }
}

export const getPaidOnSinceDate = (paidOnSince: PAID_ON_SINCE) => {
    const getDate = (paidOnSinceDaysOrMonths: number, dayOrMonth: 'day' | 'month') =>
        dayjs().startOf('day').subtract(paidOnSinceDaysOrMonths, dayOrMonth);

    switch (paidOnSince) {
        case PAID_ON_SINCE.LAST_12_MONTHS:
            // TODO: This is a temporary fix to avoid timezone issues. Will be updated after CBH-1879
            return getDate(12, 'month').add(1, 'day').format(AppConfig.isoDateFormat);
        case PAID_ON_SINCE.LAST_6_MONTHS:
            return getDate(6, 'month').format(AppConfig.isoDateFormat);
        case PAID_ON_SINCE.LAST_3_MONTHS:
            return getDate(3, 'month').format(AppConfig.isoDateFormat);
        default:
            return getDate(30, 'day').format(AppConfig.isoDateFormat);
    }
};

export const getDueOnBeforeDate = (dueOnBefore: DUE_ON_BEFORE) => {
    let dueOnBeforeDate: string;

    const getDate = (dueOnBeforeDays: number) =>
        dayjs().startOf('day').add(dueOnBeforeDays, 'day').format(AppConfig.isoDateFormat);

    switch (dueOnBefore) {
        case DUE_ON_BEFORE.NEXT_7_DAYS:
            dueOnBeforeDate = getDate(7);
            break;
        case DUE_ON_BEFORE.NEXT_14_DAYS:
            dueOnBeforeDate = getDate(14);
            break;
        case DUE_ON_BEFORE.NEXT_20_DAYS:
            dueOnBeforeDate = getDate(20);
            break;
        case DUE_ON_BEFORE.NEXT_25_DAYS:
            dueOnBeforeDate = getDate(25);
            break;
        case DUE_ON_BEFORE.NEXT_30_DAYS:
        default:
            dueOnBeforeDate = getDate(30);
    }

    return dueOnBeforeDate;
};

export const determineInvoiceState = (isTimekeeper: boolean, invoiceStatus: INVOICE_STATUS) => {
    // Invoice state will always be Consolidated for timekeeper, irrespective of status except PAID
    if (isTimekeeper && invoiceStatus !== INVOICE_STATUS.PAID) {
        return INVOICE_STATE.CONSOLIDATED;
    }
    const pendingStatuses = [
        INVOICE_STATUS.READY_TO_REVIEW,
        INVOICE_STATUS.IN_REVIEW,
        INVOICE_STATUS.IN_REVISION,
        INVOICE_STATUS.READY_TO_SUBMIT
    ];
    if (invoiceStatus === INVOICE_STATUS.PAID) {
        return INVOICE_STATE.PAID;
    } else if (pendingStatuses.includes(invoiceStatus)) {
        return INVOICE_STATE.PENDING;
    } else {
        return INVOICE_STATE.OUTSTANDING;
    }
};

export const getInvoiceTypeDisplayName = (type: INVOICE_TYPE) => {
    switch (type) {
        case INVOICE_TYPE.EPDF:
            return INVOICE_TYPE_DISPLAY_NAME.EPDF;
        case INVOICE_TYPE.EBILLING:
            return INVOICE_TYPE_DISPLAY_NAME.EBILLING;
        case INVOICE_TYPE.PHYSICAL:
            return INVOICE_TYPE_DISPLAY_NAME.PHYSICAL;
    }
};
